<template>
  <div>
    <a-row>
      <a-col :span="12">
        <div class="activity">
          <div class="title">
            <span><i></i>活动预告</span>
            <a @click="goRouter('activityList')">更多<i></i></a>
          </div>
          <div>
            <ul>
              <li v-for="item in advertisingList" :key="item.urlid" :title="item.title">
                <b>【公告】</b>
                <a @click="goRouter('newsDetail',item.urlid,0)">{{ item.title }}</a>
              </li>
            </ul>
          </div>
        </div>
      </a-col>
      <a-col :span="12">
        <div class="book-rec fr">
          <img :src="recommendedList.picturepath" @click="goRouter('bookDetail',recommendedList.doi)"/>
          <div class="book-info">
            <h4 @click="goRouter('bookDetail',recommendedList.doi)" :title="recommendedList.name">{{ recommendedList.name}}</h4>
            <span>{{ recommendedList.author }} 著</span>
            <p>
              {{ recommendedList.digest }}
            </p>
          </div>
          <div class="btn-detail">
            <a @click="goRouter('bookDetail',recommendedList.doi)">书籍详情</a>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "upcoming-events",
  props: {
    advertisingList: {
      type: Array,
    },
    recommendedList: {
      type: Object
    }
  },
  components: {},
  data() {
    return {
      
    };
  },
  created() {},
  mounted() {
  },
  methods: {
    goRouter(name,query,type) {
      this.$router.push({
        name: name,
        params: { doi: query ,type:type}
      });
    },
  },
};
</script>
<style lang="less" scoped>
.btn-detail {
  margin-top: -37px;
}
.book-rec {
  img {
    cursor: pointer;
  }
}
.book-info {
  h4:hover {
    color: #d4000e;
  }
  h4 {
    cursor: pointer;
  }
}
</style>
