<template>
  <div>
    <div>
      <div class="title">
        <span> <i></i>数字资源 </span>
        <a href="#">更多<i></i></a>
      </div>
      <div class="tab">
        <a-radio-group
          v-model="type"
          style="margin-bottom: 16px"
          @change="tabChange(type)"
        >
          <a-radio-button class="btnClass" value="books"> 图书 </a-radio-button>
          <!-- <a-radio-button class="btnClass" value="periodical">
            期刊
          </a-radio-button> -->
          <a-radio-button class="btnClass" value="listenToBooks">
            听书
          </a-radio-button>
          <a-radio-button class="btnClass" value="video"> 视频 </a-radio-button>
        </a-radio-group>
        <div class="content">
          <a-row>
            <a-col :span="18">
              <div
                class="content-book"
                :class="{
                  'content-play': type === 'video' || type === 'listenToBooks',
                }"
                v-for="item in resourceData"
                :key="item.sys_fld_doi"
              >
                <div class="imgbox">
                  <img
                    :src="item.sys_fld_coverpath"
                    v-if="type === 'books'"
                    :title="item.name"
                    @click="goRouter('bookDetail', item.sys_fld_doi)"
                  />
                  <img
                    :src="item.coverpath"
                    v-if="type === 'periodical'"
                    :title="item.name"
                    @click="goPage(item.visitelink)"
                  />
                  <img
                    :src="item.sys_fld_coverpath"
                    v-if="type === 'listenToBooks'"
                    :title="item.name"
                    @click="goRouter('AudioDetail', item.sys_fld_doi)"
                  />
                  <img
                    :src="item.sys_fld_coverpath"
                    v-if="type === 'video'"
                    :title="item.name"
                    @click="goRouter('videoDetail', item.sys_fld_doi)"
                  />
                  <div
                    class="icon-play"
                    v-if="type === 'video'"
                    :title="item.name"
                    @click="goRouter('videoDetail', item.sys_fld_doi)"
                  >
                    <img src="~@/assets/images/icon-play.png" />
                  </div>
                  <div
                    class="icon-play"
                    v-if="type === 'listenToBooks'"
                    :title="item.name"
                    @click="goRouter('AudioDetail', item.sys_fld_doi)"
                  >
                    <img src="~@/assets/images/btn-play.png" />
                  </div>
                </div>

                <div
                  class="nameClass"
                  v-if="type === 'books'"
                  :title="item.name"
                  @click="goRouter('bookDetail', item.sys_fld_doi)"
                >
                  {{ item.name }}
                </div>
                <!-- <div class="nameClass" v-if="type === 'listenToBooks'">
                  {{ item.name }}
                </div> -->
                <!-- <div
                  class="nameClass"
                  v-if="type === 'video'"
                  @click="goRouter('videoDetail', item.sys_fld_doi)"
                >
                  {{ item.name }}
                </div> -->
                <div
                  class="nameClass"
                  v-if="type === 'periodical'"
                  :title="item.name"
                  @click="goPage(item.visitelink)"
                >
                  {{ item.name }}
                </div>
                <div class="text" v-if="type === 'video'">
                  <h4
                    @click="goRouter('videoDetail', item.sys_fld_doi)"
                    :title="item.name"
                  >
                    {{ item.name }}
                  </h4>
                  <p>{{ item.issuedep }}</p>
                </div>
                <div
                  class="text"
                  v-if="type === 'listenToBooks'"
                  @click="goRouter('AudioDetail', item.sys_fld_doi)"
                >
                  <h4 :title="item.name">{{ item.name }}</h4>
                  <p>{{ item.issuedep }}</p>
                </div>
                <div class="companyName" v-if="type === 'books'">
                  {{ item.issuedep }}
                </div>
                <div class="companyName" v-if="type === 'periodical'">
                  {{ item.description }}
                </div>
              </div>
            </a-col>
            <a-col :span="6">
              <div class="rank">
                <h4>排行榜</h4>
                <ul>
                  <li
                    v-for="(item, index) in leaderboardList"
                    :key="item.sys_fld_doi"
                  >
                    <div @click="goRouterByType(type, item)" class="divBox">
                      <span>{{ index + 1 }}</span>
                      <div class="text-content" :title="item.name">
                        {{ item.name }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as homeApi from "@/services/home";
import { replacePath } from "@/utils/util";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Digital-resources",
  components: {},
  data() {
    return {
      type: "books",
      leaderboardList: [],
      resourceData: [],
      bookLeaderboardList: [],
      bookList: [],
      timeout: null,
    };
  },
  created() {
    //获取数字资源图书列表
    this.getBookList();
  },
  computed: {
    // swiper() {
    //   return this.$refs.mySwiper.swiper;
    // },
    ...mapGetters("account", ["user"]),
  },
  mounted() {},
  methods: {
    ...mapMutations("account", ["saveNowRouter"]),
    goRouterByType(type, item) {
      console.log(type);
      console.log(item);
      if (type === "books") {
        this.$router.push({
          name: "bookDetail",
          params: {
            doi: item.sys_fld_doi,
          },
        });
      } else if (type === "periodical") {
        console.log(
          "排行点击：https://bianke.cnki.net/p/Account/whValidatorAutoLoginAccess?token=Fjfr8gyjWL2pIKJvs&url=" +
            item.visitelink
        );
        //window.open("https://bianke.cnki.net/p/Account/whValidatorAutoLoginAccess?token=Fjfr8gyjWL2pIKJvs&url="+ item.visitelink,"_blank");

        var tempa = document.createElement("a");
        tempa.href =
          "https://bianke.cnki.net/p/Account/whValidatorAutoLoginAccess?token=Fjfr8gyjWL2pIKJvs&url=" +
          item.visitelink;
        document.getElementsByTagName("body")[0].appendChild(tempa);
        tempa.click();
      } else if (type === "listenToBooks") {
        var vm = this;
        if (!this.user || this.user === "") {
          this.$confirm({
            title: "确认提示",
            content: `登录后即可阅读，是否确认登录？`,
            okType: "warning",
            onOk() {
              vm.saveNowRouter(vm.$route);
              vm.$router.push({ name: "Login" });
            },
            onCancel() {
              console.log("取消");
            },
          });
          return;
        }
        if (
          localStorage.getItem("expireAt") &&
          new Date().getTime() >=
            new Date(localStorage.getItem("expireAt")).getTime()
        ) {
          this.$confirm({
            title: "确认提示",
            content: `登录已过期，该操作需重新登录，是否重新登录？`,
            okType: "warning",
            onOk() {
              vm.saveNowRouter(vm.$route);
              vm.$router.push({ name: "Login" });
            },
            onCancel() {
              console.log("取消");
            },
          });
          return;
        }

        //听书
        this.$router.push({
          name: "AudioDetail",
          params: {
            doi: item.sys_fld_doi,
          },
        });
      } else if (type === "video") {
        //视频
        var vmvideo = this;
        if (!this.user || this.user === "") {
          this.$confirm({
            title: "确认提示",
            content: `登录后即可查看，是否确认登录？`,
            okType: "warning",
            onOk() {
              vmvideo.saveNowRouter(vmvideo.$route);
              vmvideo.$router.push({ name: "Login" });
            },
            onCancel() {
              console.log("取消");
            },
          });
          return;
        }
        if (
          localStorage.getItem("expireAt") &&
          new Date().getTime() >=
            new Date(localStorage.getItem("expireAt")).getTime()
        ) {
          this.$confirm({
            title: "确认提示",
            content: `登录已过期，该操作需重新登录，是否重新登录？`,
            okType: "warning",
            onOk() {
              vm.saveNowRouter(vm.$route);
              vm.$router.push({ name: "Login" });
            },
            onCancel() {
              console.log("取消");
            },
          });
          return;
        }
        this.$router.push({
          name: "videoDetail",
          params: {
            doi: item.sys_fld_doi,
          },
        });
      }
    },
    //跳转到外部
    goPage(url) {
      console.log(
        "期刊点击：https://bianke.cnki.net/p/Account/whValidatorAutoLoginAccess?token=Fjfr8gyjWL2pIKJvs&url=" +
          url
      );
      //window.open("https://bianke.cnki.net/p/Account/whValidatorAutoLoginAccess?token=Fjfr8gyjWL2pIKJvs&url="+ url,"_blank");
      var tempa = document.createElement("a");
      tempa.href =
        "https://bianke.cnki.net/p/Account/whValidatorAutoLoginAccess?token=Fjfr8gyjWL2pIKJvs&url=" +
        url;
      document.getElementsByTagName("body")[0].appendChild(tempa);
      tempa.click();
    },
    //跳转
    goRouter(name, query) {
      if (name === "AudioDetail" || name === "videoDetail") {
        var vm = this;
        if (!this.user || this.user === "") {
          this.$confirm({
            title: "确认提示",
            content: `登录后即可查看，是否确认登录？`,
            okType: "warning",
            onOk() {
              vm.saveNowRouter(vm.$route);
              vm.$router.push({ name: "Login" });
            },
            onCancel() {
              console.log("取消");
            },
          });
          return;
        }
        if (
          localStorage.getItem("expireAt") &&
          new Date().getTime() >=
            new Date(localStorage.getItem("expireAt")).getTime()
        ) {
          this.$confirm({
            title: "确认提示",
            content: `登录已过期，该操作需重新登录，是否重新登录？`,
            okType: "warning",
            onOk() {
              vm.saveNowRouter(vm.$route);
              vm.$router.push({ name: "Login" });
            },
            onCancel() {
              console.log("取消");
            },
          });
          return;
        }
      }
      this.$router.push({
        name: name,
        params: {
          doi: query,
        },
      });
    },
    callback(key) {
      console.log(key);
    },
    //获取视频列表页
    getvideoList() {
      let obj = {
        page: 1,
        size: 10,
      };
      this.resourceData = [];
      homeApi.getvideoList(obj).then((res) => {
        if (res.success) {
          this.bookList = [];
          this.bookLeaderboardList = res.data.list;
          this.bookLeaderboardList.map((item, index) => {
            item.sys_fld_coverpath = replacePath(item.sys_fld_coverpath);
            if (index < 6) {
              this.bookList.push(item);
            }
          });
          this.leaderboardList = this.bookLeaderboardList;
          this.resourceData = this.bookList;
        } else {
          this.bookLeaderboardList = [];
          this.bookList = [];
        }
      });
    },
    //获取音频列表页
    getAudioList() {
      let obj = {
        page: 1,
        size: 10,
        order: "SYS_FLD_HITCOUNT",
      };
      this.resourceData = [];
      homeApi.getAudioList(obj).then((res) => {
        if (res.success) {
          this.bookList = [];
          this.bookLeaderboardList = res.data.list;
          this.bookLeaderboardList.map((item, index) => {
            item.sys_fld_coverpath = replacePath(item.sys_fld_coverpath);
            if (index < 6) {
              this.bookList.push(item);
            }
          });
          this.leaderboardList = this.bookLeaderboardList;
          this.resourceData = this.bookList;
        } else {
          this.bookLeaderboardList = [];
          this.bookList = [];
        }
      });
    },
    //获取期刊列表页
    getjournalList() {
      let obj = {
        page: 1,
        size: 10,
        type: "0",
      };
      this.resourceData = [];
      homeApi.getjournalList(obj).then((res) => {
        if (res.success) {
          this.bookList = [];
          this.bookLeaderboardList = res.data.list;
          this.bookLeaderboardList.map((item, index) => {
            item.coverpath = replacePath(item.coverpath);
            if (index < 8) {
              this.bookList.push(item);
            }
          });
          this.resourceData = this.bookList;
        } else {
          this.bookLeaderboardList = [];
          this.bookList = [];
        }
      });
      let content = {
        page: 1,
        size: 10,
        type: "1",
      };
      homeApi.getjournalList(content).then((res) => {
        if (res.success) {
          this.leaderboardList = res.data.list;
        } else {
          this.leaderboardList = [];
        }
      });
    },
    //获取数字资源图书列表
    getBookList() {
      let obj = {
        page: 1,
        size: 10,
      };
      homeApi.getBookList(obj).then((res) => {
        if (res.success) {
          this.resourceData = [];
          this.leaderboardList = res.data.list;
          this.leaderboardList.map((item, index) => {
            item.sys_fld_coverpath = replacePath(item.sys_fld_coverpath);
            if (index < 8) {
              this.resourceData.push(item);
            }
          });
        } else {
          this.bookLeaderboardList = [];
          this.bookList = [];
        }
      });
    },
    //切换图书期刊按钮
    tabChange(type) {
      console.log("1");
      //防抖操作 防止同一时间内点击多次造成异步查询的问题
      let vm = this;
      vm.resourceData = [];
      // 清除定时器  防抖操作 只执行最后一次点击
      if (this.timeout !== null) clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        if (type === "books") {
          vm.getBookList();
        } else if (type === "periodical") {
          vm.getjournalList();
        } else if (type === "listenToBooks") {
          //听书
          vm.getAudioList();
        } else if (type === "video") {
          //视频
          vm.getvideoList();
        }
      }, 600);
    },
  },
};
</script>
<style lang="less" scoped>
.tab {
  padding: 28px 60px 28px 60px;
  img {
    cursor: pointer;
  }
  .content {
    padding-top: 10px;
    .rank {
      padding-top: 20px;
      padding-left: 82px;
      text-align: left;
      ul li {
        line-height: 17px;
        padding: 10px 0;
        .divBox {
          position: relative;
          span {
            height: 20px;
            width: 20px;
            line-height: 20px;
          }
          .text-content {
            position: absolute;
            left: 30px;
            top: 2px;
            font-size: 14px;
            color: #101010;
            text-decoration: none;
            display: inline-block;
            width: 85%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .text-content:hover {
            color: #d4000e;
            cursor: pointer;
          }
        }
      }
    }
    .content-book {
      width: 116px;
      height: 182px;
      float: left;
      margin-right: 12.5%;
      padding-top: 20px;
      margin-bottom: 50px;
      text-align: left;
      .nameClass {
        width: 100%;
        font-size: 16px;
        padding-top: 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle;
        text-align: center;
      }
      .nameClass:hover {
        color: #d4000e;
        cursor: pointer;
      }
      .companyName {
        font-size: 14px;
        color: #8d8d8d;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
      .imgbox {
        position: relative;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
        .icon-play {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 70px;
          height: 70px;
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }
      }
    }
    .content-book:nth-child(4n + 0) {
      margin-right: 0;
    }
    .content-play {
      width: 245px;
      height: 144px;
      border: 2px solid #e9e9e9;
      margin-right: 15px;
      padding-top: 0;
      margin-top: 15px;
      margin-bottom: 110px;
    }
    .content-play:nth-child(4n + 0) {
      margin-right: 15px;
    }
    .content-play:nth-child(3n + 0) {
      margin-right: 0;
    }
    .text {
      text-align: center;
      border: 1px solid #e8e8e8;
      h4 {
        width: 100%;
        font-size: 16px;
        padding: 15px 0;
        border-bottom: 2px solid #e9e9e9;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
      h4:hover {
        cursor: pointer;
        color: #d4000e;
      }
      p {
        font-size: 14px;
        color: #8d8d8d;
        line-height: 32px;
        height: 22px;
      }
    }
  }
}

.btnClass {
  font-size: 16px;
  padding: 0 35px;
  margin: 0 24px;
  height: 36px;
  line-height: 36px;
  border: 1px solid #e9e9e9;
  border-radius: 19px;
}
/deep/.ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
/deep/ .ant-radio-button-wrapper-checked {
  color: #fff !important;
  background: #d4000e !important;
  border-color: #fff !important;
}
/deep/.ant-radio-button-wrapper:hover {
  color: #fff !important;
  background: #d4000e !important;
  border-color: #fff !important;
}
</style>
