<template>
  <div>
    <div>
      <div class="title">
        <span> <i></i>新闻资讯 </span>
        <a @click="goRouter('news')">更多<i></i></a>
      </div>
      <ul class="news-list">
        <li v-for="item in advertisingList" :key="item.id">
          <h4>
            <a @click="goRouter('newsDetail',item.urlid)" :title="item.title">{{ item.title }}</a>
          </h4>
          <div class="news clearfix">
            <a href="#" class="news-pic fl">
              <img :src="item.sys_fld_coverpath" @click="goRouter('newsDetail',item.urlid)"/>
            </a>
            <div class="news-info fr">
              <p>
                {{ item.digest }}
              </p>
              <div>
                <span>{{ item.pubdate }}</span>
                <a @click="goRouter('newsDetail',item.urlid)">查看详情</a>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "News",
  components: {},
  props:{
    advertisingList:{
      type: Array
    }
  },
  data() {
    return {

    };
  },
  created() {},
  methods: {
    //跳转
    goRouter(name, query) {
      this.$router.push({
        name: name,
        params: {
          doi: query,
          type:1
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.news-info div {
  padding-top: 20px;
}
.news-pic img {
  width: 100%;
  height: 100%;
}
</style>
