<template>
  <div>
    <div>
      <div id="banner">
        <a-carousel :dot-position="dotPosition" arrows>
          <div
            slot="prevArrow"
            class="custom-slick-arrow"
            style="left: 80px; zindex: 1"
          >
            <img src="~@/assets/images/arrow-l.png" alt="" />
          </div>
          <div slot="nextArrow" class="custom-slick-arrow" style="right: 80px">
            <img src="~@/assets/images/arrow-r.png" alt="" />
          </div>
          <!-- <div>
            <img src="../../assets/images/banner.jpg" alt="a" />
          </div>
          <div>
            <img src="../../assets/images/banner.jpg" alt="b" />
          </div>
          <div>
            <img src="../../assets/images/banner.jpg" alt="c" />
          </div> -->
          <div v-for="item in adList" :key="item.doi">
            <a :href="item.adlink">
              <img :src="item.pictruepath" :alt="item.name"
            /></a>
          </div>
        </a-carousel>
      </div>
    </div>
    <div id="main">
      <div class="activity">
        <upcomingEvents
          :advertisingList="advertisingList"
          :recommendedList="recommendedList"
        ></upcomingEvents>
      </div>
      <div class="digital-resources">
        <DigitalResources></DigitalResources>
      </div>
      <div class="main-item main-adver">
        <template v-if="indexMidAd!==null&&indexMidAd.length>0">
          <img :src="indexMidAd[0].pictruepath" :alt="indexMidAd[0].name" :href="indexMidAd[0].adlink"/>
        </template>
        <template v-else>
          <img src="~@/assets/images/adver-0.jpg" alt=""  />
        </template>
        
      </div>
      <div class="news">
        <News :advertisingList="newsList"></News>
      </div>
    </div>
  </div>
</template>

<script>
import * as homeApi from "@/services/home";
import { adDoiList, recommendDoiList } from "@/config/constConfig";
import { replacePath, isMobile } from "@/utils/util";
import upcomingEvents from "@/components/indexComponent/UpcomingEvents.vue";
import DigitalResources from "@/components/indexComponent/DigitalResources.vue";
import News from "@/components/indexComponent/News";

export default {
  name: "Index",
  components: { upcomingEvents, DigitalResources, News },
  data() {
    return {
      adList: [], //轮播图数据
      dotPosition: "bottom", //轮播按钮位置
      advertisingList: [], //活动列表信息
      newsList: [], //资讯列表信息
      recommendedList: {}, //推荐图书列
      bookList: [], //图书列
      bookLeaderboardList: [], //排行榜
      indexMidAd: [],
    };
  },
  created() {
    this.Mobile();
    //获取轮播图信息 1轮播图 2腰图
    this.getAdList(1);
    this.getAdList(2);
    //获取活动信息
    this.getAdvertisingList();
    //获取推荐图书
    this.getRecommendList();
    //获取资讯信息
    this.getnewsList();
  },
  methods: {
    Mobile() {
      if (isMobile()) {
        this.$router.push({ name: "mobileIndex" });
      }
    },
    //获取推荐图书列
    getRecommendList() {
      let obj = {
        page: 1,
        size: 3,
        typeDoi: recommendDoiList.indexBookDoi,
        tag: "1",
      };
      homeApi.getRecommendList(obj).then((res) => {
        if (res.success) {
          let returnArr = res.data.list;
          returnArr.map((item) => {
            item.picturepath = replacePath(item.picturepath);
          });
          this.recommendedList = returnArr[0];
        } else {
          this.recommendedList = {};
        }
      });
    },
    //获取轮播图信息
    getAdList(type) {
      let obj = {};
      if (type === 1) {
        obj = {
          page: 1,
          size: 10,
          type: adDoiList.indexDOi,
        };
      } else {
        obj = {
          page: 1,
          size: 1,
          type: adDoiList.indexMidDoi,
        };
      }
      homeApi.getAdList(obj).then((res) => {
        if (res.success) {
          if (type === 1) {
            this.adList = res.data.list;
            this.adList.map((item) => {
              item.pictruepath = replacePath(item.pictruepath);
            });
          } else {
            this.indexMidAd = res.data.list;
            this.indexMidAd.map((item) => {
              item.pictruepath = replacePath(item.pictruepath);
            });
          }
        } else {
          if (type === 1) {
            this.adList = [];
          } else {
            this.indexMidAd = [];
          }
        }
      });
    },
    //获取活动信息
    getAdvertisingList() {
      let obj = {
        page: 1,
        size: 4,
        typeid: "0",
      };
      homeApi.getNewsList(obj).then((res) => {
        if (res.success) {
          this.advertisingList = res.data.list;
          this.advertisingList.map((item) => {
            item.sys_fld_coverpath = replacePath(item.sys_fld_coverpath);
          });
        } else {
          this.advertisingList = [];
        }
      });
    },
    //获取资讯信息
    getnewsList() {
      let obj = {
        page: 1,
        size: 4,
        typeid: "1",
      };
      homeApi.getNewsList(obj).then((res) => {
        if (res.success) {
          this.newsList = res.data.list;
          this.newsList.map((item) => {
            item.sys_fld_coverpath = replacePath(item.sys_fld_coverpath);
          });
        } else {
          this.newsList = [];
        }
      });
    },
    // onSearch(value) {
    //   this.$router.push({ name: "searchList", params: { key: value } });
    // },
  },
};
</script>
<style lang="less">
@import "~@/assets/css/home.less";
@import "~@/assets/css/user-center.less";
</style>
<style lang="less" scoped>
#banner {
  background: none !important;
  width: 100%;
  height: 380px;
  .slick-list a {
    width: 100%;
  }
  .slick-list img {
    width: 100%;
    height: 380px;
  }
}
#main {
  background: #f0f2f5;
  width: 100%;
  .news {
    width: 1200px;
    //   height: 687px;
    background: #fff;
    margin: 0 auto;
    position: relative;
    padding: 20px;
    border-radius: 15px;
    box-shadow: #ccc;
    margin-top: 24px;
  }
  .activity {
    width: 1200px;
    height: 277px;
    background: #fff;
    margin: 0 auto;
    margin-top: -50px;
    position: relative;
    padding: 20px;
    border-radius: 15px;
    box-shadow: #ccc;
    /* position: absolute; */
    /* left: 10%;
  right: 10%;
  top: -50px; */
  }
  .main-item {
    width: 1200px;
    margin: 0 auto;
    // height: 112px;
  }
  .digital-resources {
    width: 1200px;
    //   height: 687px;
    background: #fff;
    margin: 0 auto;
    position: relative;
    padding: 20px;
    border-radius: 15px;
    box-shadow: #ccc;
    margin-top: 24px;
  }
}
.ant-carousel {
  .slick-slide {
    text-align: center;
    height: 380px;
    line-height: 160px;
    background: red;
    overflow: hidden;
  }
}
.ant-carousel {
  .slick-slide {
    h3 {
      color: red;
    }
  }
}
.ant-carousel {
  .custom-slick-arrow {
    width: 50px;
    height: 50px;
    z-index: 9;
  }
}
.ant-carousel img {
  .custom-slick-arrow {
    img {
      width: 50px;
      height: 50px;
    }
  }
}
.ant-carousel {
  .custom-slick-arrow:before {
    display: none;
  }
}
.ant-carousel {
  .custom-slick-arrow:hover {
    opacity: 0.8;
  }
}
.ant-carousel {
  .slick-dots-bottom {
    bottom: 75px;
  }
}
.ant-carousel li button {
  .slick-dots {
    li {
      button {
        opacity: 1;
        height: 8px;
        border-radius: 4px;
        background: #ccc;
      }
    }
  }
}
// .ant-carousel .slick-dots li.slick-active button {
//   background: red;
// }
</style>
<style  scoped>
.ant-carousel >>> .slick-dots-bottom {
  bottom: 75px;
}
</style>
